import React from 'react'
import Song from './Components/Song/Song'
import WelcomeHeader from './Components/WelcomeHeader/WelcomeHeader'

import './index.css'

const App = () => {
    return (
        <div>
            <WelcomeHeader/>
            <Song name="Brauðmoli" membercount="3" done="5" total="12" />
            <Song name="Sykursætur" />
            <Song name="Brauðmoli"/>
            <Song name="Brauðmoli"/>
            <Song />
            <Song />
            <Song />
            <Song />
            <Song />
            <Song />
            <Song />
            <Song />
        </div>
    )
}

export default App
