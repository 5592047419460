import React from 'react'
import ProgressBar from './ProgressBar';

import './Song.css'


function Song(props) {
    const name = props.name;
    const numTeamMembers = props.membercount;
    const done = props.done;
    const total = props.total;

    return (
        <div className="song__container">
            <div className = "song__joke">

                <div className="song__name__container">
                    <div className="song__name">
                       {name}
                    </div>
                    <div className="song__team">
                         {numTeamMembers} Team Members
                    </div> 
                </div>
                    <div className="song__progress">
                        <ProgressBar done={done} total={total} />
                    </div>
            </div>
        </div>
    )
}

export default Song
