import React from 'react'

import './WelcomeHeader.css'

function WelcomeHeader() {
    return (
        <div className="welcome__container">
            Hello, Georg Bjarnfreðarson
        </div>
    )
}

export default WelcomeHeader
