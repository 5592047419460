function ProgressBar(props) {
    const percent = parseInt((props.done / props.total)*100);

    return (
        <div className="progress__placement">
            {percent}% Done
            <div className="progress__bar__empty" />
            <div className="progress__bar__filled"/>
        </div>
    )
}

export default ProgressBar
